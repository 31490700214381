/* --------------------------------------------------------------
    Import CSS Dependencies
-------------------------------------------------------------- */
@import 'colors';
@import 'icons';
@import 'fonts';
@import 'default';
@import 'nav';
@import 'buttons';
/* --------------------------------------------------------------
    Start Project CSS
-------------------------------------------------------------- */
html {
  position: relative;
  height: 100%;
  background-color: #fff;
  // overflow-y: scroll;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}
@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
body {
  height: 100%;
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
  color: #555555;
}
.wrap {
  padding: 30px 0;
}
.container-fluid.but-fixed {
  max-width: 1356px;
}
.footer {
  padding-top: 20px;
  padding-bottom: 15px;
  width: 100%;
  background-color: #f5f5f5;
  color: #999999;
  border-top: 1px solid #e4e4e4;
  p {
    margin: 0 0 5px 0;
  }
  p.trustmark {
    display: none;
    margin: 0;
  }
  a {
    color: #999999;
  }
  .social a {
    border: 0;
  }
  img {
    display: inline-block;
  }
}

/* --------- asc styles --------- */
#header {
  float: none;
  a.logo {
    float: left;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 0;
    width: 75%;
    max-width: 650px;
    img {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      height: auto;
      width: 100%;
      max-width: 295px;
      &.asc-logo {
        display: none;
        max-width: 60px;
      }
    }
    span.big,
    span.small {
      display: inline;
      font-size: 1.5em;
      font-weight: 500;
      color: #555;
      line-height: 1em;
      vertical-align: middle;
    }
    span.big {
      font-weight: 600;
      font-family:
        'Open Sans',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        'Helvetica Neue',
        Arial,
        'Noto Sans',
        sans-serif,
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
        'Noto Color Emoji';
    }
    span.small {
      display: none;
      margin-top: 0;
      font-size: 16px;
      font-family: 'Open Sans', Verdana;
      font-weight: normal;
    }
  }
  #support {
    display: none;
    float: right;
    margin: 0 0 0 0;
    width: auto;
    p {
      float: right;
      margin: 2px 12px 0 0;
      strong {
        font-size: 16px;
      }
    }
    a.login {
      float: right;
      margin: 0 0 0 0;
      padding: 0 12px 2px 12px;
      font-weight: normal;
      color: #fff;
      background: #3f3f3f;
      text-decoration: none;
      border-radius: 0 0 4px 4px;
      border: 0;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e828';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
      &:hover {
        background: lighten(#3f3f3f, 7.5%);
      }
    }
    span.phone {
      display: none;
      padding-right: 8px;
      border-right: 1px dotted #333;
    }
    a.livechat {
      // float: right;
      margin: 0 0 0 10px;
      font-weight: normal;
      color: #555;
      &:before {
        display: inline-block;
        margin-right: 5px;
        width: inherit;
        height: inherit;
        content: '\e830';
        color: inherit;
        font-family: 'icons';
        font-weight: normal;
        text-align: center;
        font-size: 105%;
        vertical-align: top;
      }
    }
  }
  #state {
    display: none;
    margin: 15px 0;
    float: right;
    clear: right;
    width: 250px;
    position: relative;
    z-index: 100;
  }
}
#header-wrapper {
  // border-bottom: 1px solid #ccc;
  position: relative;
  &.home {
    background: #9fb2cd url(../images/home-bg.jpg) no-repeat 50% 50% / cover;
  }
  &.california {
    background: #9fb2cd url(../images/california-state-bg.jpg) no-repeat 50% 50% / cover;
  }
  &.florida {
    background: #9fb2cd url(../images/florida-state-bg.jpg) no-repeat 50% 100% / cover;
  }
  &.georgia {
    background: #9fb2cd url(../images/georgia-state-bg.jpg) no-repeat 50% 50% / cover;
  }
  &.indiana {
    background: #9fb2cd url(../images/indiana-state-bg.jpg) no-repeat 50% 50% / cover;
  }
  &.oklahoma {
    background: #fff url(../images/oklahoma-state-bg-2.jpg) no-repeat 50% 0 / cover;
  }
  &.pennsylvania {
    background: #9fb2cd url(../images/pennsylvania-state-bg.jpg) no-repeat 50% 50% / cover;
  }
  &.texas {
    background: #fff url(../images/texas-state-bg.jpg) no-repeat 50% 5% / cover;
    #banner-wrap {
      position: relative;
      z-index: 10;
    }
    &:before {
      background-color: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 0;
      content: '';
    }
  }
  &.colorado,
  &.illinois,
  &.ohio {
    #banner {
      h1 {
        color: #333;
        text-shadow: none;
      }
    }
  }
  &.virginia {
    background: #9fb2cd url(../images/virginia-state-bg.jpg) no-repeat 50% 50% / cover;
  }
  #header-wrap {
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 11;
  }
  #banner {
    h1,
    h2 {
      color: #fff;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    }
  }
  ul.bigger {
    font-size: 1.3rem;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    font-family: 'Fjalla One', Impact, 'Open Sans', Verdana, Arial, Helvetica, sans-serif;
    li {
      margin-bottom: 0.1em;
    }
  }
}
.dropdown-list {
  max-width: 300px;
  width: 100%;
  span.dropdown.cta-dropdown {
    padding: 15px;
    padding-bottom: 14px;
    color: #fff;
    font-weight: 400;
    background: #ff6431;
    border: 0;
    width: 100%;
    height: auto;
    line-height: 1;
    font-size: 22px;
    text-align: left;
    font-family: 'Fjalla One', 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    & + .drop {
      top: 50px;
    }
  }
}
#steps-wrap {
  .icon h3 {
    margin: 0 0 10px 0;
    position: relative;
    text-align: left;
    padding-left: 65px;
    min-height: 40px;
    span {
      display: inline-block;
      vertical-align: middle;
    }
    &:before {
      display: inline-block;
      margin: 0 10px;
      width: 40px;
      height: 40px;
      content: '';
      background: transparent url(../images/icon-steps.png) no-repeat 0 0 / cover;
      vertical-align: middle;
      position: absolute;
      left: 0;
    }
    &.steps-alt:before {
      background: transparent url(../images/steps-icon.png) no-repeat 0 0 / cover;
    }
    &.step-2:before {
      background-position: -40px 0;
    }
    &.step-3:before {
      background-position: -80px 0;
    }
  }
}
#action-wrap {
  &.dark {
    border-top: 2px solid #f93;
    border-bottom: 2px solid #f93;
    background-color: rgba(0, 0, 0, 0.75);
  }
  &.light {
    border-top: 1px solid #d5d5d8;
    border-bottom: 1px solid #d5d5d8;
    background-color: #e6e8f7;
  }
}
#content-wrap {
  &.home {
    background-color: #e4f2ff;
  }
}
blockquote {
  font-size: 1.45rem;
  line-height: 1.2;
  font-weight: 400;
  color: inherit;
  &.headline {
    font-size: 1.75rem;
  }
  p {
    font-family: Georgia, 'Open Sans', 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
    font-style: italic;
    &:before,
    &:after {
      margin-right: 5px;
      display: inline-block;
      content: '\201C';
      color: inherit;
      opacity: 0.5;
      font-family: serif;
      font-size: 32px;
      font-weight: 700;
      vertical-align: middle;
      font-style: normal;
    }
    &:after {
      margin-left: 5px;
      content: '\201D';
    }
  }
  a {
    color: inherit;
  }
}
#reviews-wrap {
  background: #fafafa;
  blockquote {
    color: #777;
  }
}
.text-theme-primary {
  color: $theme-primary !important;
}
.blurb {
  margin-top: -55px;
  position: absolute;
  width: 100%;
  transform: translate(-50%, 0);
  color: #f60;
  font:
    normal 21px 'Fjalla One',
    Impact,
    'Open Sans',
    Helvetica,
    Arial,
    Verdana,
    Tahoma,
    sans-serif;
  &.blurb-special {
    margin-top: -70px;
    .blurb-free {
      display: block;
      font:
        600 14px 'Open Sans',
        Helvetica,
        Arial,
        Verdana,
        Tahoma,
        sans-serif;
      color: #333;
    }
  }
  &.blurb-small {
    display: block;
    margin-bottom: 7px;
    position: relative;
    transform: none;
    margin-top: 0;
    font-size: 18px;
  }
}
.point {
  position: relative;
  padding-left: 1.5em;
  &:before {
    display: block;
    margin-top: 0;
    width: 1em;
    height: inherit;
    color: inherit;
    font-size: 1em;
    font-weight: normal !important;
    content: '\e840';
    font-family: 'icons';
    position: absolute;
    left: 0;
  }
}
a.practice-test-link {
  display: block;
  margin: 10px auto;
  padding: 10px 15px 10px 55px;
  background: #fff url(../images/arrow.png) no-repeat 10px center;
  text-decoration: none;
  border: 1px solid #09f;
  color: #444;
  &:hover {
    text-decoration: underline;
    border: 1px solid #f60;
  }
}
#article-container {
  .article-image {
    object-fit: cover;
  }
  *:disabled {
    background-color: #ebebeb;
  }
}
.articles-card {
  border-top: 3px solid #0099ff !important;
}
.article-footer {
  .next-post {
    float: right;
  }
}
.blog-image-size {
  max-width: 75%;
}
/* ----------------------------------------------------------------------------
    Page Media Queries
----------------------------------------------------------------------------- */
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  #header-wrapper {
    #banner {
      h1 {
        font-size: 2rem;
      }
    }
  }
  #steps-wrap {
    .icon h3 {
      padding-left: 0;
      text-align: center;
      &:before {
        display: block;
        margin: 0 auto 10px auto;
        width: 60px;
        height: 60px;
        position: relative;
        left: unset;
      }
      &.step-2:before {
        background-position: -60px 0;
      }
      &.step-3:before {
        background-position: -120px 0;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .container-fluid.but-fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
  #header {
    a.logo {
      width: 60%;
      img.ftd-logo {
        margin-right: 15px;
      }
      img.asc-logo {
        display: block;
      }
    }
    #support {
      display: block;
    }
  }
  #header-wrapper {
    #banner {
      h1 {
        font-size: 2.5rem;
      }
    }
    &.florida {
      #banner {
        h1 {
          font-size: 2.3rem;
        }
      }
    }
    &.colorado {
      #banner {
        background: transparent url(../images/colorado-state-bg.png) no-repeat -40px 100%;
        background-size: 55%;
      }
    }
    &.illinois {
      #banner {
        background: transparent url(../images/illinois-adult-bg.png) no-repeat -40px 70%;
        background-size: 65%;
      }
    }
    &.georgia {
      #banner {
        background: transparent url(../images/teenagers.png) no-repeat 20px 100%;
        background-size: 45%;
      }
    }
    &.indiana {
      #banner {
        background: transparent url(../images/teenagers2.png) no-repeat 15% 100%;
        background-size: 40%;
      }
    }
    &.ohio {
      #banner {
        background: transparent url(../images/ohio-state-bg.png) no-repeat 0px 70%;
        background-size: 55%;
      }
    }
    &.pennsylvania {
      #banner {
        background: transparent url(../images/teenagers2.png) no-repeat 10% 100%;
        background-size: 40%;
      }
    }
  }
}

// roughtly 900px/16px
@media only screen and (min-width: 56.25em) {
  #footer p.trustmark {
    display: block;
  }
  #header a.logo {
    width: 50%;
    span.small {
      font-size: 18px;
    }
  }
  #header-wrap {
    border-top: 3px solid #3f3f3f;
  }
  #header-wrapper {
    #banner {
      h1 {
        font-size: 3rem;
      }
    }
    &.pennsylvania {
      #banner {
        h1 {
          font-size: 2.5rem;
        }
      }
    }
    ul.bigger {
      font-size: 1.5rem;
    }
  }
  #steps-wrap {
    .icon h3 {
      &:before {
        width: 120px;
        height: 120px;
      }
      &.step-2:before {
        background-position: -120px 0;
      }
      &.step-3:before {
        background-position: -240px 0;
      }
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  #header {
    a.logo {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 50%;
      span.small {
        font-size: 18px;
      }
    }
    #support {
      display: block;
      span.phone {
        display: inline-block;
      }
    }
  }
  #header-wrapper {
    &.home {
      #banner {
        h1 {
          font-size: 4rem;
        }
      }
    }

    &.colorado {
      #banner {
        h1 {
          font-size: 4.2rem;
        }
      }
    }
    &.illinois {
      #banner {
        h1 {
          font-size: 4rem;
        }
      }
    }
    &.pennsylvania {
      #banner {
        h1 {
          font-size: 3rem;
        }
      }
    }
    &.texas {
      ul.bigger {
        font-size: 2rem;
      }
    }
    ul.bigger {
      font-size: 1.7rem;
    }
  }
  .point {
    padding-left: 3em;
    &:before {
      font-size: 2.5em;
      color: #cccccc;
      top: -5px;
    }
  }
}

// Large devices (desktops, 1115px and up)
@media (min-width: 1115px) {
  #header {
    #state {
      display: block;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #header-wrapper {
    &.ohio {
      #banner {
        background-size: auto;
      }
    }
  }
}

// Extra large devices (large desktops, 1400px and up)
@media (min-width: 1400px) {
  .container-fluid.but-fixed {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
